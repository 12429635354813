import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightCol from '../components/RightCol'
import { Container, Row, Col } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import slugify from 'slugify'
import marked from 'marked'

const Tags = ({ pageContext, data, location }) => {
  const { category } = pageContext
  const ctgry = data.sanityCategory
  console.log('data.categorypost', data.allSanityPost)
  const { edges, totalCount } = data.allSanityPost
  const features = data.filterPost.edges
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${category}"`

  return (
    <Layout location={location} title={category}>
      <SEO title={tagHeader} description={data.site.siteMetadata.description} />
      <section className="category-page py-5">
        <Container className="py-5">
          <div className="intro mb-5">
            <h4 className="text-secondary">
              <Link className="text-secondary" to="/blog">
                All posts
              </Link>{' '}
              / <span className="text-blue text-capitalize">{ctgry.title}</span>
            </h4>
          </div>
          <Row>
            <Col md={`${8} pr-md-5`}>
              {edges.map(({ node }) => {
                return (
                  <Row key={node.id} className="mb-5">
                    <LazyLoadComponent>
                      <Col md={4}>
                        <div
                          className="category-list-banner mb-2"
                          style={{
                            backgroundImage: `url(${node.mainImage &&
                              node.mainImage.asset &&
                              node.mainImage.asset &&
                              node.mainImage.asset.fluid &&
                              node.mainImage.asset.fluid.src})`,
                            backgroundPosition: `center center`,
                            backgroundSize: `cover`,
                            height: `180px`,
                          }}
                        />
                      </Col>
                      <Col xs={8}>
                        <div className="category-list-content">
                          <h5 className="mb-4">
                            <Link
                              className="text-blue-13"
                              to={`/${node.slug.current}`}
                            >
                              {node.title}
                            </Link>
                          </h5>
                          {node.excerpt ? (
                            <div
                              className="mb-4"
                              dangerouslySetInnerHTML={{
                                __html: marked(node.excerpt),
                              }}
                            />
                          ) : null}

                          <div className="category-list-author">
                            <p>
                              <span className="text-secondary">By</span>{' '}
                              {node.authors.length !== 0 && (
                                <Link
                                  className="text-black"
                                  to={`/author/${node.authors[0].person.slug.current}`}
                                >
                                  {node.authors[0].person.name}{' '}
                                </Link>
                              )}
                              <span className="text-secondary">in</span>{' '}
                              <span className="text-blue text-capitalize">
                                {node.categories.length !== 0
                                  ? node.categories.map(cat => (
                                      <Link
                                        to={`/category/${slugify(
                                          cat.title.toLowerCase()
                                        )}`}
                                        key={cat.title}
                                        className="cat-link"
                                      >
                                        {cat.title}{' '}
                                        <span className="d-inline">,</span>
                                      </Link>
                                    ))
                                  : null}
                              </span>
                              <span className="text-secondary">
                                {node.publishedAt
                                  ? node.publishedAt
                                  : node._createdAt}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </LazyLoadComponent>
                  </Row>
                )
              })}
            </Col>
            <Col md={`${4} pl-md-5`}>
              <div className="right-content">
                <RightCol features={features} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Tags
// filter: { frontmatter: { categories: { in: [$category] } } fileAbsolutePath:{regex: "/(posts)/"} }
export const pageQuery = graphql`
  query($id: String) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }

    sanityCategory(id: { eq: $id }) {
      id
      title
    }

    filterPost: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { featuredBlog: { eq: true } }
      limit: 4
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }

    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
